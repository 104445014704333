.lp-pricing-section {
    /* padding: 50px 0; */
  }
  
  /* .pricing-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
  } */

  .enterprise {
    color: black;
}

  .pricing-card {
    background-color: #FFF6EA;
    border-radius: 30px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    position: relative;
    border: 1px solid #FC9700;
    margin: 0px 0px 20px 0px;
}

/* Ensures the list doesn't exceed a specific height and becomes scrollable */
.pricing-body .feature-list {
    list-style: none; /* Remove default bullets */
    padding-left: 0;  /* Remove left padding */
    padding-right: 52px; /* Add padding on the right side to create space for the scrollbar */
    max-height: 400px; /* Set a max height for the list */
    overflow-y: auto;  /* Allow vertical scrolling when content exceeds max height */
    /* border-right: 2px solid orange; */
  }
  
  /* Customize the scrollbar for webkit browsers */
  .pricing-body .feature-list::-webkit-scrollbar {
    width: 8px; /* Set the scrollbar width */
    padding-right: 50px;
  }
  
  .pricing-body .feature-list::-webkit-scrollbar-thumb {
    background-color: orange; /* Scrollbar thumb color */
    border-radius: 4px; /* Optional: gives the scrollbar a rounded appearance */
    padding-right: 50px;
  }
  
  .pricing-body .feature-list::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    padding-right: 50px;
  }


  
  
  
  /* .pricing-header {
    background-color: #fc9700;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    color: white;
    text-align: center;
  } */

  .lp-sec-heading {
    justify-items: center;
    text-align-last: center;
}

  .pricing-header {
    background-color: #fc9700;
    padding: 20px;
    /* border-radius: 10px 10px 0 0; */
    color: white;
    text-align: center;
    border-radius: 30px 30px 0px 0px;
}
  
  .pricing-header h2 {
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  /* .pricing-header .price {
    background-color: #fdbf61;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
  } */

  .pricing-header .price {
    background-color: #fdbf61;
    /* padding: 10px; */
    margin-top: 10px;
    border-radius: 5px;
    font-size: 25px;
    font-weight: bold;
}
  
  .pricing-header .original-price {
    text-decoration: line-through;
    color: black;
    margin-right: 10px;
  }
  
  .pricing-header .discounted-price {
    color: white;
    font-weight: bold;
  }
/* Ensure the check icon and text appear in black */
.pricing-body ul {
    list-style: none; /* Remove default list bullets */
    padding-left: 0;  /* Remove padding */
  }
  
  .pricing-body li {
    font-size: 16px;  /* Adjust font size for the list */
    line-height: 1.6; /* Adjust line height */
    display: flex;    /* Use flexbox to align icon and text */
    align-items: center; /* Center the icon vertically with the text */
  }
  
  .pricing-body li i {
    color: black; /* Make the check icon black */
    margin-right: 10px; /* Add space between the icon and text */
    font-size: 18px; /* Adjust icon size */
  }
  
  
  

  .pricing-body {
    padding: 30px 0px 0px 30px;
}
  
  .pricing-body h5 {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: black;
  }
  
  .pricing-body ul {
    list-style: none;
    padding: 0;
  }
  
  .pricing-body ul li {
    font-size: 1rem;
    color: black;
    margin: 10px 0;
  }
  
  .start-project-btn {
    background-color: #fc9700;
    color: black;
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .start-project-btn:hover {
    background-color: #e68900;
  }
  
  .pricing-footer {
    /* border-top: 2px solid #fc9700; */
    text-align: center;
  }
  
  /* .footer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  } */

  .footer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border-top: 2px solid #fc9700;
    padding: 10px 30px 10px 30px;
    font-size: 73px;
    background-color: #F1EFEF;
    border-radius: 0px 0px 30px 30px;
}
.footer-info a {
  color: black;
  text-decoration: none;
  animation: blinker 1s linear infinite;
}
  
  .footer-info .left-footer,
  .footer-info .right-footer {
    font-size: 1.1rem;
    color: orange;
  }
  
  .footer-info .divider {
    width: 1px;
    height: 50px;
    background-color: orange;
  }
  
  /* .best-seller-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
  }
   */

   .best-seller-badge {
    position: absolute;
    top: -20px;
    right: -6px;
    width: 70px;
}

.easy-payment-plan {
  padding-top: 20px;
    position: relative;
    display: inline-block;
  }
  
  .tooltip-icon {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000;
    padding: 4px 8px; /* Slightly larger for better click area */
    cursor: pointer;
    margin-left: 8px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    position: relative;
  }
  
  .tooltip-content {
    display: none;
    position: absolute;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;  /* Increased padding for larger box */
    width: 250px;   /* Increased width of the tooltip */
    top: 100%;
    left: 0;
    z-index: 1;
    white-space: normal;
    font-size: 14px;  /* Slightly increased font size */
    line-height: 1.5;
    text-align: left;  /* Text aligned to the left */
  }
  
  .easy-payment-plan:hover .tooltip-content {
    display: block;
  }
  
  /* Tooltip triangle adjustment */
  .tooltip-content::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 10px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  p.choose-service-description {
    text-align: center;
}
  


@media (max-width: 768px) {
    .pricing-card {
        background-color: #FFF6EA;
        border-radius: 30px;
        /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
        /* padding: 20px; */
        position: relative;
        border: 1px solid #FC9700;
        margin: 20px 0px 20px 0px;
    }

}