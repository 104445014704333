
.thankyou-page {
    background-image: linear-gradient(0deg, #F7991C, #fff);
    color: #fff;
    padding: 100px 100px 50px;
    text-align: center;
}

.thankyou-page img {
    width: 400px;
}

.contact {
    text-align: center;
    background-color: white;
    color: rgb(0, 0, 0);
    padding: 50px;
}
.thankyou-page h1 {
    font-size: 60px;
    margin-bottom: 10px;
}

.thankyou-page p {
    font-size: 18px;
    margin-bottom: 30px;
}

.thankyou-page a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.thankyou-page .btn {
    background-color: #FFCE86;
    color: #FFCE86;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s;
}



.thankyou-page .btn:hover {
    background-color: #FB9130;
}
