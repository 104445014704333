/* TestimonialSection.css */
.testimonial-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.testimonial-card {
    background-color: #F5F2ED;
    padding: 20px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    border: 1px solid;
    height: 40vh;
}



.testimonial-card:hover {
    background-color: #FC9700;
    color: #fff;
}

.testimonial-card:hover .quote-icon,
.testimonial-card:hover .testimonial-text,
.testimonial-card:hover .testimonial-name {
    color: #fff;
}

.quote-icon {
    font-size: 2.6rem;
    color: #BFBFBF;
}

.testimonial-text {
    color: #000;
    font-size: 1rem;
    margin: 15px 0;
}

.testimonial-name {
    color: #4f4f4e;
}

.col-12.arrows {
    justify-items: center;
}

.swiper-button-prev,
.swiper-button-next {
    position: relative;
    background-color: #FC9700;
    color: black;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    /* Optional: For visibility */
    padding: 10px;
    border-radius: 50%;
    /* Optional: For circular buttons */
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    background-color: #333;
    color: #fff;
}



.swiper-button-prev a,
.swiper-button-next a {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* For screen 992px to 1199px */
@media (max-width: 1199.98px) {
    .testimonial-card {
        height: 39vh;
    }

}


@media (max-width: 991.98px) {
    .testimonial-card {
        height: 47vh;
    }
}

/* For screen 576px to 767px */
@media (max-width: 767.98px) {
    .testimonial-card {
        height: 50vh;
    }
}
