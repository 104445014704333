.top-head {
    color: #FC9700;
	background: #ffffff;
    /* margin: 0; */
    padding:0;
    
}

/* LPHeader Section */
.lpheader {
    background-color: #FC9700;
    /* padding: 15px 0; */
  }
  

  
  .lpheader .navbar-nav {
    margin-left: auto;
  }
  
  .lpheader .nav-link {
    padding: 10px 15px;
    border-radius: 20px;
    margin: 5px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
  }
  
  .lpheader .custom-btn {
    color: white;
    background-color: transparent;
    border: 2px solid white;
    display: inline-block;  /* Make sure buttons are displayed by default */
  }

  /* Override the default navbar toggler icon background */
  .navbar-toggler-icon {
    --bs-navbar-toggler-icon-bg: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E);
}



  /* Navbar Toggler (Hamburger) */
  .navbar-toggler {
    border-color: #000000;
    margin-right: 20px;
    border: 4px solid;
}
  .lpheader .get-started {
    color: #FC9700;
    border-color: #FC9700;
    background-color: white;
  }
  
  .lpheader .live-chat {
    color: #FC9700;
    border-color: #FC9700;
    background-color: white;
  }
  
  .lpheader .nav-link:hover {
    background-color: white;
    color: #FC9700;
  }
  
  /* Mobile View */
  @media (max-width: 767px) {
    .lpheader .nav-link {
      display: block;
      width: 50%;
      text-align: center;
      place-self: center;
  }
  
    .lpheader .nav-item {
      width: 100%;
      margin: 5px 0;
    }
  
  }
  
  /* Ensure Buttons are visible in Desktop View */
  @media (min-width: 768px) {
    .navbar-toggler {
      border-color: rgb(0, 0, 0);
      margin-right: 20px;
      border: 4px solid;
  }
    .lpheader .nav-item {
      display: inline-block; /* Ensure nav items are inline */
    }
  
    .lpheader .nav-link {
      display: inline-block; /* Ensure nav links are inline */
    }
  }


  @media (min-width: 768px) and (max-width: 992px) {
    .lpheader .nav-link {
      display: block;
      width: 50%;
      text-align: center;
      place-self: center;
  }
  
    .lpheader .nav-item {
      width: 100%;
      margin: 5px 0;
    }


    /* Your styles here */
}

  


  