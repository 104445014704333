/* AwardsLogo Section */
/* .choose-service-icon-box img {
    width: 71px;
} */

.awards-logo-section {
    padding: 5rem 0;
    background: #5D5D5D;
}


.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}

.awards-logo-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
}

.awards-logo-section .swiper-container {
    margin-top: 30px;
}

.awards-logo-section .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.awards-logo-section .awards-logo-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.awards-logo-section .swiper-container img {
    transition: transform 0.3s ease-in-out;
}

.awards-logo-section .swiper-container img:hover {
    transform: scale(1.05);
}

/* Make images responsive */
.awards-logo-section .img-fluid {
    max-width: 100%;
    height: auto;
}

/*  start whychooseservice css */
.lightblue-h2 {
    color: #3DD8E0;
}

h2.choose-service-heading.font-weight-bold {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
}

/* end whychoose service */

/* Start uniquesection */
.unique-section-container {
    background: linear-gradient(to right, #F8F9FA, #F3F4F6);
    border-radius: 50px;
    padding: 15px 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.unique-section-text {
    font-size: 30px;
    font-weight: 500;
    margin: 0;
}


.unique-section-button {
    border-radius: 50px;
    background-color: #3DD8E0;
    border-color: white;
}

.unique-section-button:hover {
    background-color: #2780C3;
}

.unique-section-link:hover {
    text-decoration: underline;
}

/* End uniquesection */

/* Media Queries for Responsiveness */
@media (max-width: 640px) {
    h2.choose-service-heading.font-weight-bold {
        font-size: 2rem;
        font-weight: bold;
        color: #333;
    }

    .awards-logo-section h2 {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    h2.choose-service-heading.font-weight-bold {
        font-size: 2rem;
        font-weight: bold;
        color: #333;
    }

    .awards-logo-section h2 {
        font-size: 2.2rem;
    }
}

/* For screen 768px to 991px */
@media (max-width: 991.98px) {
    .unique-section-text {
        font-size: 25px;
    }
}

/* For screen 576px to 767px */
@media (max-width: 767.98px) {
    .unique-section-text {
        font-size: 17px;
    }
}

/* For screen 480px to 575px */
@media (max-width: 575.98px) {
    .unique-section-text {
        font-size: 14px;
    }

    .unique-section-button {
        font-size: 14px;
    }
}

/* For screen 320px to 479px */
@media (max-width: 479.98px) {
    .unique-section-text {
        width: 56%;
    }
}
